import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../../components/Layout"
import Icon from "../../components/Icon"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { getString, formatFrontmatterDate } from "../../util"
import Link from "../../components/IntlLink"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

export default function PageTemplate({ pageContext: { language, availableLanguages }, data }) {

	const { placeholder, mdx } = data

	const cx = classNames.bind(styles);
	const f = mdx.frontmatter

	const EachLink = ({ to, icon, alt, href=to }) => (
		<span>
			<a href={href} target="_blank" rel="nofollow noopener noreferrer">
				<span className="inline-svg">
					<Icon icon={icon} alt={alt} />
				</span>
				<span className="printonly"> {to}</span>
			</a>
		</span>
	)

	const SocialLinks = () => (
		<div className={cx("links")}>
			{f.website && <EachLink to={f.website} icon="home" alt="Website" />}
			{f.email && <EachLink to={f.email} href={`mailto:${f.email}`} icon="email" alt="Email" />}
			{f.facebook && <EachLink to={f.facebook} icon="facebook" alt="Facebook" />}
			{f.twitter && <EachLink to={f.twitter} icon="twitter" alt="Twitter" />}
			{f.linkedin && <EachLink to={f.linkedin} icon="linkedin" alt="LinkedIn" />}
			{f.github && <EachLink to={f.github} icon="github" alt="GitHub" />}
		</div>
	)

	const image = f.image || placeholder.nodes[0]

	const pubList = {
		dp: "Discussion Paper",
		pierspectives: "PIERspectives",
		abridged: "aBRIDGEd",
		blog: `PIER Blog`,
	}

	return (
		<Layout
			lang={language}
			availableLanguages={availableLanguages}
			mdx={mdx}
			customTitle={true}
			indexThis={!f.exclude}
			collection={"members"}
		>

			<div className={cx("newGrid")}>

				<div className={cx("profile")}>
						<GatsbyImage image={image.childImageSharp.main} alt={f.title} />
						{!f.exclude && <span hidden data-typesense-field="img_url">{getSrc(image.childImageSharp.thumbnail)}</span>}
				</div>

				<div className={cx("head")}>
					{
						f.exclude
						? <h1 className={cx("name", "align")}>
								{f.title}
							</h1>
						: <h1 className={cx("name", "align")} data-typesense-field="title">
								{f.title}
							</h1>
					}
					{!f.exclude && language === "th" &&
						<span hidden data-typesense-field="title_alt">{f.memberid}</span>
					}
					<div className={cx("org", "align")} data-typesense-field="body">{getString(f.org, language)}</div>
					<div className={cx("align")}>
						{/* {f.prnOfficer &&
							<Link to="/pier-research-network/#prn-officers">
								<span className="blob-small-span right-margin-mobile-center">PRN Officer</span>
							</Link>
						} */}
						{f.firstVisit &&
							<Link to="/visiting-fellows/">
								<span className="blob-small-span right-margin-mobile-center">Visiting Fellow</span>
							</Link>
						}
						{(f.org === "สถาบันวิจัยเศรษฐกิจป๋วย อึ๊งภากรณ์" || f.org === "Puey Ungphakorn Institute for Economic Research" || f.org === "pier") &&
							<Link to="/staff/">
								<span className="blob-small-span right-margin-mobile-center">Staff</span>
							</Link>
						}
					</div>
					<SocialLinks />
				</div>

			</div>

			<div className={cx("body")}>
				<div id="mdx-body">
					<MDXRenderer>
						{mdx.body}
					</MDXRenderer>
				</div>
				{f.fields &&
					<div>
						<h4>Research Interests</h4>
						<ul>
						{
							f.fields.map((e, i) => 
								<li className={cx("expertise")} key={i} data-typesense-field="body">{e}</li>
							)
						}
						</ul>
					</div>
				}
				{Object.entries(pubList).map((pubType, i) => {
					if (data[pubType[0]].totalCount > 0) {
						return(
							<div key={i}>
								<h4>{pubType[1]}</h4>
								{
									data[pubType[0]].nodes.map((item, i) =>
										<div className={cx("pub")} key={i}>
											<div className="date orange">{formatFrontmatterDate(item.frontmatter, language)}</div>
											<div>
                        {/* {pubType[0] === "dp" && <span>DP {item.frontmatter.id}</span>} */}
                        <Link to={item.fields.originalPath}>{item.frontmatter.title}</Link>
                      </div>
										</div>
									)
								}
								{data[pubType[0]].totalCount > data[pubType[0]].nodes.length &&
									<div className={cx("pub")} key={i}>
										<div />
										<div>
											<Link to={`/search/?authors=${f.title}&collection=${pubType[0]}`}>
												[ {language === "th" ? "ดูทั้งหมด" : "View all"} ]
											</Link>
										</div>
									</div>
								}
							</div>
						)
					}
					return null
				})}
			</div>

		</Layout>
	)
}

export const pageQuery = graphql`
	query MemberQuery($id: String, $memberid: String) {
		placeholder: allFile(
			filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "placeholder"}, name: {eq: "user"}}
		) {
			nodes {
				childImageSharp {
					thumbnail: gatsbyImageData(layout: FIXED, width: 160, height: 160)
					main: gatsbyImageData(layout: CONSTRAINED)
				}
			}
		}
		mdx(id: { eq: $id }) {
			id
			body
			fields {
				originalPath
				draft
			}
			frontmatter {
				memberid
				exclude
				title
				org
				website
				email
				facebook
				twitter
				linkedin
				github
				fields
				image {
					publicURL
					childImageSharp {
						thumbnail: gatsbyImageData(layout: FIXED, width: 160, height: 160)
						main: gatsbyImageData(layout: CONSTRAINED)
					}
				}
				firstVisit
				prnOfficer
			}
		}
		dp: allMdx(
			filter: {fields: {collection: {eq: "dp"}}, frontmatter: {authors: {glob: $memberid}}},
			sort: {fields: frontmatter___date, order: DESC},
			limit: 5
		) {
			nodes {
				fields {
					originalPath
				}
				frontmatter {
					title
					date
          id
				}
			}
			totalCount
		}
		pierspectives: allMdx(
			filter: {fields: {collection: {eq: "pierspectives"}}, frontmatter: {authors: {glob: $memberid}}},
			sort: {fields: frontmatter___date, order: DESC},
			limit: 5
		) {
			nodes {
				fields {
					originalPath
				}
				frontmatter {
					title
					date
				}
			}
			totalCount
		}
		abridged: allMdx(
			filter: {fields: {collection: {eq: "abridged"}}, frontmatter: {authors: {glob: $memberid}}},
			sort: {fields: frontmatter___date, order: DESC},
			limit: 5
		) {
			nodes {
				fields {
					originalPath
				}
				frontmatter {
					title
					date
				}
			}
			totalCount
		}
		blog: allMdx(
			filter: {fields: {collection: {eq: "blog"}}, frontmatter: {authors: {glob: $memberid}}},
			sort: {fields: frontmatter___date, order: DESC},
			limit: 5
		) {
			nodes {
				fields {
					originalPath
				}
				frontmatter {
					title
					date
				}
			}
			totalCount
		}
	}
`